*,
*::before,
*::after {
  box-sizing: inherit;
  scroll-behavior: auto !important;
}
html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
  height: 100%;
}

html body {
  overflow-x: inherit;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  font-weight: 600 !important;
  position: relative;
}
.main {
  height: auto;
  flex-grow: 1;
}
.wrapper {
  /* min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
}

.rtl {
  direction: rtl;
  text-align: right;
}

.rtl h1,
.rtl p,
.rtl button {
  margin-right: 10px;
  margin-left: 0;
}

.paragraph,
.li-checkout,
.text,
.text-footer {
  font-size: 16px !important;
  text-align: center !important;
  font-weight: 400;
  line-height: 1.8rem;
  /* letter-spacing: 0.04rem; */
}
li {
  font-weight: 700;
  font-size: 16px !important;
  color: gray;
}

.text {
  color: #2d2d2d !important;
  font-size: 15px !important;
  text-align: left !important;
  font-weight: 600;
}

.hint-text {
  font-size: 12px !important;
  font-weight: 500;
  display: inline-block;
  line-height: 2;
  width: 15rem;
}

.info {
  font-weight: 400;
  font-size: 11px !important;
  width: 18rem;
}

.form-label {
  width: 15rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  position: relative;
}

.header {
  position: relative;
  object-fit: cover;
  background-size: cover;
  width: 100%;
  height: 50vh;
  padding: 0;
}
.col-logo {
  text-align: center;
}
.logo {
  width: 120px;
  margin: 20px 0;
}

.paragraph {
  display: block;
  text-align: center !important;
  font-size: 18px;
  margin-bottom: 30px;
}

.input-wrapper {
  display: inline-flex;
  justify-content: center;
}

.MuiInputBase-input {
  width: 240px !important;
}

.paypal {
  display: inline-flex;
  justify-content: center;
}

.amount-label {
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #adaeaf;
}

.form-check-label {
  font-size: 14px;
}

.newsletter {
  width: fit-content;
  margin-left: 49rem;
  margin-top: 20px;
  margin-bottom: 30px;
  margin: 0;
}

.input-amount {
  position: fixed;
  width: 10%;
  left: 53rem;
  margin-right: 50px !important;
  margin-top: 60px !important;
}

.paypal-row {
  margin-right: 28rem;
}

.btn-layout {
  position: absolute;
  top: 0;
  margin-top: 15px;
  width: fit-content;
  gap: 7px;
}

.translate-btn {
  border: none;
  background-color: transparent;
  border-radius: 10px;
  background-color: #1565c0;
  color: white;
  margin-right: 5px;
  font-size: 14px;
}
.translate-btn:hover {
  background-color: #1b86ff;
}
.translate-btn:active {
  background-color: transparent !important;
}
.thanks {
  text-transform: uppercase;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  width: fit-content !important;
  display: inline-block !important;
}
.amount-boxes-wrapper {
  display: flex;
  gap: 15px;
  padding: 0 !important;
  justify-content: center;
}

.nav-button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.icon-button {
  padding: 0.625rem 1.5rem 0.5rem;
  border-color: transparent;
  font-size: 0.75rem;
  line-height: 1.5;
}
.button-color {
  border-color: transparent !important;
  background-color: #4da084 !important;
}
.button-color:hover {
  background-color: #71ecc3 !important;
}
.icon-button:hover {
  border-color: transparent;
}

.list {
  list-style: none;
}

.impressum-text {
  text-align: justify !important;
  font-size: 14px;
}

.email-input {
  width: 14rem;
  font-size: 12px;
  width: 15rem;
}

.ramadan-page-download-link {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 40px;
  justify-content: center;
  padding-right: 0;
}

.ramadan-calendar-download-btn {
  background-color: #38cc8d;
  border-color: #3cd594;
}
.ramadan-calendar-download-btn:hover {
  background-color: #237f58;
  border-color: #3cd594;
}

.paragraph-ramadan-screen {
  display: block;
  font-size: 18px;
  font-size: 16px !important;
  text-align: center !important;
  color: gray;
  font-weight: 400;
  line-height: 1.8rem;
  margin-top: 10px;
}

.footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-button-elements {
  background-color: transparent !important;
  border-color: transparent !important;
  color: gray;
  text-decoration: underline;
  width: auto;
}

.row-qr-code {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.figure-caption {
  font-size: 12px;
  color: #adaeaf;
}

.card-img {
  max-width: 100%;
  width: 18rem;
}
.card {
  text-align: center;
  align-items: center;
}

.card-group-ramadan-screen {
  margin-left: 30px; /* Adjust as needed */
  margin-right: 30px; /* Adjust as needed */
}

.nav-link {
  text-decoration: none;
  color: #adaeaf;
  margin-right: 8px;
  font-weight: 500;
}

.nav-link:hover {
  color: #000 !important;
  font-weight: 500 !important;
}

.ramadan-screen-header-img {
  object-fit: cover;
  background-size: cover;
  width: 100%;
  height: 50vh;
  padding: 0;
}

/*ramadan page*/
.container-ramadan-page {
  width: 100%;
  display: flex;
  padding: 4px;
  text-align: center;
  margin-top: 5rem;
  justify-content: center;
  /* height: 78vh; */
  margin-bottom: 40px;
}
.row-ramadan {
  width: 100%;
  flex-direction: row;
  padding: 0;
  margin: 10px;
}

.btn-card {
  background-color: transparent;
  border-color: transparent;
  width: 100%;
  padding: 0;
}
.card {
  border: none;
}

.card-img {
  cursor: pointer;
  transition: 0.3s all;
  border-radius: 3px;
  overflow: hidden;
  padding: 0;
  border: 10px #333;
  border-style: groove;
  border-radius: 15px;
  background-size: cover;
  height: 267px;
}

.no-card-img {
  overflow: hidden;
  padding: 0;
  border: 10px #333;
  border-style: groove;
  border-radius: 15px;
  background-size: cover;
  height: 267px;
}

.card-img:hover {
  filter: brightness(1.1);
  opacity: 1;
  transition: all 0.45s ease;
}

.footer-container {
  padding: 0px !important;
  margin-bottom: -12px !important;
  margin-top: 10px !important;
}

.basket-screen-card {
  margin-bottom: 30px;
}

.href-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  letter-spacing: 1px;
}

.carousel-video {
  position: relative;
  top: 25%;
  left: 25%;
  -moz-transform: scaleX(1.3);
  filter: brightness(70%) saturate(70%) blur(1px) contrast(70%);
}

/* styles for small screens */
/*when the width of the screen is less or equal to. */
@media screen and (max-width: 480px) {
  /* css rules go here */
  p,
  li {
    font-size: 15px !important;
  }
  .hint-text {
    font-size: 10px !important;
  }
  .newsletter {
    margin-left: 5rem;
    width: 50%;
  }
  .input-amount {
    width: 40%;
    left: 6.1rem;
  }
  .thanks {
    width: 100%;
    font-size: 26px;
    animation: 0.4 ease-in;
  }
  .amount-boxes-wrapper {
    margin-bottom: 20px;
  }
  .paragraph {
    display: block !important;
    text-align: justify !important;
    text-align-last: center;
  }
  .list {
    padding: 0;
  }
  .contact {
    height: auto;
  }
  .container-ramadan-page {
    justify-content: center;
    margin-top: 20px;
  }
  .row-ramadan {
    flex-direction: column;
  }
  .container-cards {
    flex-direction: column-reverse;
  }
  .ramadan-screen-header-img {
    /* min-width: 480px; */
    min-width: fit-content;
  }
  .row-qr-code {
    gap: 10px;
  }
  .footer-flex {
    flex-direction: column-reverse;
  }
}

/* styles for medium screens */
@media screen and (min-width: 481px) and (max-width: 768px) {
  /* css rules go here */
  .newsletter {
    margin-left: 12rem;
    width: 50%;
  }
  .input-amount {
    width: 40%;
    left: 13.4rem;
  }

  .amount-boxes-wrapper {
    margin-bottom: 20px;
  }
  .container-cards {
    gap: 35px;
    flex-direction: column;
  }
}

/* styles for large screens */
/*it when the width of the screen is greater than or equal to 769*/
@media screen and (min-width: 769px) {
  /* css rules go here */
  .newsletter {
    margin-left: 12rem;
    width: 50%;
  }
  .input-amount {
    width: 40%;
    left: 1.4rem;
  }
  .amount-boxes-wrapper {
    margin-bottom: 20px;
  }
}
.contact {
  height: 82vh;
}
