.new-carousel {
  position: relative;
}

.new-carousel > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.carousel-slide {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: 200ms opacity ease-in-out;
  transition-delay: 200ms;
}

.carousel-slide > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(90%) saturate(142%) blur(0.1px) contrast(60%);
}

.active {
  opacity: 1;
}

.carousel-button {
  position: absolute;
  background: none;
  z-index: 2;
  border: none;
  font-size: 4rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.carousel-button:hover,
.carousel-button:focus {
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
}

.prev {
  left: 1rem;
}

.next {
  right: 1rem;
}
