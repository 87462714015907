.ratio-16x9 {
  --bs-aspect-ratio: 56.25% !important;
}
.ratio {
  position: relative;
  width: 35% !important;
}
/* "": "" */

@media (max-width: 768px) {
  .ratio {
    width: 94% !important;
  }
}

@media (max-width: 667px) and (max-height: 375px) {
  .ratio {
    width: 87% !important;
  }
}
