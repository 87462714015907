.dropdown-menu {
  margin-right: 20px !important;
}

@media (min-width: 768px) {
  .carousel-caption {
    margin-bottom: 30px;
  }
}
@media (min-width: 1024px) {
  .carousel-caption {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .carousel-caption {
    margin-bottom: -5px;
  }
}
