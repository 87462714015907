.quran {
  font-weight: 300;
  font-family: "Open Sans", sans-serif !important;
}
.paragraph {
  font-weight: 300;
  text-align: center !important;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio {
  position: relative;
  width: 41%;
}

.accordion-button {
  background-color: #4da08478 !important;
}

@media (max-width: 768px) {
  .ratio {
    width: 91%;
  }
}

@media (max-width: 667px) and (max-height: 375px) {
  .ratio {
    width: 81%;
  }
}
