.paragraph-landingpage {
  font-size: 16px !important;
  text-align: center !important;
  font-weight: 400;
  line-height: 1.8rem;
}

.language-buttons {
  z-index: 2;
}

.landing-page-header {
  position: relative;
  height: 100% !important;
  overflow: hidden;
}

.landing-page-header video {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(90%) saturate(42%) blur(3px) contrast(86%);
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
}

.overlay-header {
  margin-bottom: 40px;
}

.urgent {
  font-size: 3.3rem;
  font-weight: 700;
  color: #f8f9fa;
}

.urgent-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing-button {
  width: 100%;
  white-space: nowrap;
  margin-bottom: 20px;
}

main {
  position: relative;
  z-index: 2;
}

.carousel-caption-text,
.carousel-caption {
  /* white-space: pre-line; */
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  color: #38cc8d !important;
  filter: invert(100%);
  background-color: #41464b;
  padding: 1.2rem;
  border-radius: 50%;
}

.carousel-header-h2 {
  text-align: center;
}

.card {
  background-color: transparent;
  border: 5px solid rgba(255, 255, 255, 0.2);
  padding: 0.1rem;
  background-color: white;
  border-radius: 15px;
  height: 600px;
}

.card img {
  /* width: 300px;
  height: 200px; */
  width: 100%;
  object-fit: cover;
  height: 200px;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-body a {
  color: #38cc8d;
}

.card-text {
  width: 40rem;
}

.title {
  color: #41464b;
  padding: 20px 0;
  position: relative;
}

.title::after {
  content: '';
  position: absolute;
  display: inline-block;
  width: 44%;
  height: 1%;
  top: -19%;
  right: 28%;
  background-color: #cb7c1cbe;
}
.title::before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 1%;
  height: 72%;
  top: 7%;
  right: 0;
  background-color: #198754;

  display: none;
}

.landing-button,
.btn-primary {
  background-color: #26835f !important;
  border-color: transparent;
}

.landing-button:hover,
.btn-primary:hover {
  background-color: #38cc8d !important;
  border-color: transparent !important;
}

@media (max-width: 768px) {
  .landing-page-header {
    height: 70vh;
  }
  .carousel-caption-text,
  .carousel-caption p {
    white-space: pre-line;
    font-size: 12px !important;
    text-align: center;
  }
  .carousel-caption {
    margin-bottom: 10%;
  }

  .title::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 68%;
    height: 8%;
    top: -30%;
    right: 15%;
    background-color: #cb7c1cbe;
  }
  .title::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 2%;
    height: 65%;
    top: 19%;
    right: 0;
    background-color: #198754;

    display: none;
  }
}

@media (max-width: 667px) and (max-height: 375px) {
  .landing-page-header {
    height: 90vh;
  }
}

/* responsive design */

@media only screen and (max-width: 480px) {
  /* Styles for mobile devices */
  .urgent {
    font-size: 1.3rem;
    margin: 0;
  }
  .landing-button {
    padding: 5px;
    margin: 0;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  /* Styles for tablets */
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* Styles for small desktop and laptop screens */
}
